exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-support-mdx": () => import("./../../../src/pages/en/support.mdx" /* webpackChunkName: "component---src-pages-en-support-mdx" */),
  "component---src-pages-en-treatment-monitoring-stellarq-software-mdx": () => import("./../../../src/pages/en/treatment-monitoring/stellarq-software.mdx" /* webpackChunkName: "component---src-pages-en-treatment-monitoring-stellarq-software-mdx" */),
  "component---src-pages-fi-hoidonseuranta-epilepsia-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/epilepsia.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-epilepsia-mdx" */),
  "component---src-pages-fi-hoidonseuranta-huntingtonin-tauti-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/huntingtonin-tauti.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-huntingtonin-tauti-mdx" */),
  "component---src-pages-fi-hoidonseuranta-index-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/index.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-index-mdx" */),
  "component---src-pages-fi-hoidonseuranta-kajoamaton-neuromodulaatiohoito-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/kajoamaton-neuromodulaatiohoito.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-kajoamaton-neuromodulaatiohoito-mdx" */),
  "component---src-pages-fi-hoidonseuranta-kayttoonottopalvelut-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/kayttoonottopalvelut.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-kayttoonottopalvelut-mdx" */),
  "component---src-pages-fi-hoidonseuranta-liikehairiot-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/liikehairiot.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-liikehairiot-mdx" */),
  "component---src-pages-fi-hoidonseuranta-ms-tauti-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/ms-tauti.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-ms-tauti-mdx" */),
  "component---src-pages-fi-hoidonseuranta-myasthenia-gravis-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/myasthenia-gravis.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-myasthenia-gravis-mdx" */),
  "component---src-pages-fi-hoidonseuranta-saavutettavuusseloste-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/saavutettavuusseloste.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-saavutettavuusseloste-mdx" */),
  "component---src-pages-fi-hoidonseuranta-sma-ja-dmd-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/sma-ja-dmd.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-sma-ja-dmd-mdx" */),
  "component---src-pages-fi-hoidonseuranta-stellarq-alusta-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/stellarq-alusta.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-stellarq-alusta-mdx" */),
  "component---src-pages-fi-hoidonseuranta-uniapnea-ja-hengitysvaje-mdx": () => import("./../../../src/pages/fi/hoidonseuranta/uniapnea-ja-hengitysvaje.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseuranta-uniapnea-ja-hengitysvaje-mdx" */),
  "component---src-pages-fi-hoidonseurantakysely-mdx": () => import("./../../../src/pages/fi/hoidonseurantakysely.mdx" /* webpackChunkName: "component---src-pages-fi-hoidonseurantakysely-mdx" */),
  "component---src-pages-fi-tuki-mdx": () => import("./../../../src/pages/fi/tuki.mdx" /* webpackChunkName: "component---src-pages-fi-tuki-mdx" */),
  "component---src-pages-fi-tutkimuspalvelut-mdx": () => import("./../../../src/pages/fi/tutkimuspalvelut.mdx" /* webpackChunkName: "component---src-pages-fi-tutkimuspalvelut-mdx" */),
  "component---src-pages-fi-yhteystiedot-mdx": () => import("./../../../src/pages/fi/yhteystiedot.mdx" /* webpackChunkName: "component---src-pages-fi-yhteystiedot-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-contact-information-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/contact-information.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-contact-information-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-research-services-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/research-services.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-research-services-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-support-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/support.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-support-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/treatment-monitoring/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-stellarq-software-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/treatment-monitoring/stellarq-software.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-stellarq-software-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-survey-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/treatment-monitoring-survey.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-treatment-monitoring-survey-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-epilepsia-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/epilepsia.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-epilepsia-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-huntingtonin-tauti-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/huntingtonin-tauti.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-huntingtonin-tauti-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-kajoamaton-neuromodulaatiohoito-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/kajoamaton-neuromodulaatiohoito.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-kajoamaton-neuromodulaatiohoito-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-kayttoonottopalvelut-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/kayttoonottopalvelut.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-kayttoonottopalvelut-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-liikehairiot-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/liikehairiot.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-liikehairiot-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-ms-tauti-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/ms-tauti.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-ms-tauti-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-myasthenia-gravis-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/myasthenia-gravis.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-myasthenia-gravis-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-saavutettavuusseloste-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/saavutettavuusseloste.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-saavutettavuusseloste-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-sma-ja-dmd-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/sma-ja-dmd.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-sma-ja-dmd-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-stellarq-alusta-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/stellarq-alusta.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-stellarq-alusta-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-uniapnea-ja-hengitysvaje-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseuranta/uniapnea-ja-hengitysvaje.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseuranta-uniapnea-ja-hengitysvaje-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseurantakysely-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/hoidonseurantakysely.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-hoidonseurantakysely-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tuki-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/tuki.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tuki-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tutkimuspalvelut-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/tutkimuspalvelut.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tutkimuspalvelut-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-yhteystiedot-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/yhteystiedot.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-yhteystiedot-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-stellar-q-programvaran-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/stellarQ-programvaran.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-stellar-q-programvaran-mdx" */),
  "component---src-pages-se-stellar-q-programvaran-mdx": () => import("./../../../src/pages/se/stellarQ-programvaran.mdx" /* webpackChunkName: "component---src-pages-se-stellar-q-programvaran-mdx" */)
}

